<template>
  <b-card
    text-variant="center"
    class="card card-congratulations"
  >
    <!-- images -->
    <b-img
      :src="require('@/assets/images/elements/decore-left.png')"
      class="congratulations-img-left"
    />
    <b-img
      :src="require('@/assets/images/elements/decore-right.png')"
      class="congratulations-img-right"
    />
    <!--/ images -->

    <b-avatar
      variant="primary"
      size="70"
      class="shadow mb-2"
    >
      <feather-icon
        size="28"
        icon="AwardIcon"
      />
    </b-avatar>
    <h1 class="mb-1 mt-50 text-white">
      Olá {{ userData.fullName }}
    </h1>
    <b-card-text class="m-auto w-75">
      <p class="text-white">
        Seu Plano:
        <b-badge
          variant="primary"
        >{{ data.plan_name }}
        </b-badge> <br>
        Ativo até: <b-badge
          variant="primary"
        >{{ data.time_left.due_date.date | date }}
        </b-badge>
      </p>
    </b-card-text>
    <b-card-text class="mx-auto mt-2 w-75">
      <b-button
        variant="relief-warning"
        @click.stop="openRenew()"
      >
        Renovar
      </b-button>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BAvatar, BCardText, BButton, BBadge
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
    BButton,
    BBadge
  },
  filters: {
    date (val) {
      return `${moment(val).format('DD/MM/YYYY')} as ${moment(val).format('HH:mm')}`
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    userData: JSON.parse(localStorage.getItem('userData'))
  }),
  methods: {
    openRenew () {
      this.$router.push({
        name: 'Controle Infinity (Renovar)'
      })
    }
  }
}
</script>
