<template>
  <b-overlay
    id="overlay-background"
    :show="loading"
    variant="transparent"
    opacity="1"
    blur="5px"
    rounded="sm"
  >
    <b-row class="match-height">
      <b-col
        lg="6"
        md="12"
      >
        <analytics-congratulation
          v-if="!loading"
          :data="resumeData.subscription"
        />
      </b-col>

      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          icon="perfil.png"
          :statistic="kFormatter(chart.totalAccounts.result)"
          statistic-title="Total de Contas"
          :chart-data="chart.totalAccounts.series"
        />
      </b-col>

      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          icon="money-bag.png"
          color="warning"
          :statistic="kFormatter(chart.totalEarnings.result)"
          statistic-title="Ganhos Totais"
          :chart-data="chart.totalEarnings.series"
        />
      </b-col>
    </b-row>

    <b-row
      v-if="!loading"
      class="match-height"
    >
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="working.png"
          :statistic="kFormatter(resumeData.statistics.total_active)"
          statistic-title="Contas<br>Ativas"
          color="info"
        />
      </b-col>

      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="success"
          icon="warning.png"
          :statistic="kFormatter(resumeData.statistics.total_blocked_temporary)"
          statistic-title="Bloqueio<br>Temporário"
        />
      </b-col>

      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          hide-chart
          color="danger"
          icon="captcha.png"
          :statistic="kFormatter(resumeData.statistics.total_verification_sms)"
          statistic-title="Verificação<br>de SMS"
        />
      </b-col>

      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          hide-chart
          color="danger"
          icon="spam.png"
          :statistic="kFormatter(resumeData.statistics.total_verification_email)"
          statistic-title="Verificação<br>de E-mail"
        />
      </b-col>

      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          hide-chart
          color="danger"
          icon="prohibition.png"
          :statistic="kFormatter(resumeData.statistics.total_banned)"
          statistic-title="Contas Banidas"
        />
      </b-col>

      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="warning"
          icon="working-at-home.png"
          :statistic="kFormatter(resumeData.statistics.total_retired)"
          statistic-title="Contas<br>Aposentadas"
        />
      </b-col>

      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="warning"
          icon="social-media.png"
          :statistic="kFormatter(resumeData.statistics.total_movimenting)"
          statistic-title="Movimentando"
        />
      </b-col>

      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="warning"
          icon="sleepy.png"
          :statistic="kFormatter(resumeData.statistics.total_resting)"
          statistic-title="Descansando"
        />
      </b-col>

      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="warning"
          icon="password.png"
          :statistic="kFormatter(resumeData.statistics.total_change_password)"
          statistic-title="Trocar Senha"
        />
      </b-col>

      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="warning"
          icon="calendar.png"
          :statistic="kFormatter(resumeData.statistics.total_blocked_days)"
          statistic-title="Bloqueio<br>de Dias"
        />
      </b-col>

      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="warning"
          icon="face-detection.png"
          :statistic="kFormatter(resumeData.statistics.total_blocked_selfie)"
          statistic-title="Bloqueio<br>de Selfie"
        />
      </b-col>

      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="warning"
          icon="folder.png"
          :statistic="kFormatter(resumeData.statistics.total_others)"
          statistic-title="Outros"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
      >
        <Analytic
          v-if="!loading"
          :data="resumeData.statistics"
        />
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { BOverlay, BRow, BCol } from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import checkSubscription from '../checkSubscription'
import StatisticCardWithAreaChart from './StatisticCardWithAreaChart.vue'
import StatisticCardVertical from './StatisticCardVerticalMod.vue'
import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
import Analytic from './Analytic.vue'
import AccountDetails from './AccountDetails.vue'
import CardAnalyticEarningsChart from './CardAnalyticEarningsChart.vue'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    AnalyticsCongratulation,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    Analytic,
    AccountDetails,
    CardAnalyticEarningsChart
  },
  mixins: [checkSubscription],
  data: () => ({
    loading: true,
    resumeData: {
      statistics: {
        total_accounts: 0,
        total_active: 0,
        total_verification_sms: 0,
        total_verification_email: 0,
        total_blocked_temporary: 0,
        total_blocked_days: 0,
        total_blocked_selfie: 0,
        total_used_recently: 0,
        total_movimenting: 0,
        total_resting: 0,
        total_change_password: 0,
        total_banned: 0,
        total_retired: 0,
        total_others: 0,
        total_earnings: 0
      }
    }
  }),
  computed: {
    chart () {
      return {
        totalAccounts: {
          result: this.resumeData.statistics.total_accounts,
          series: [
            {
              name: 'totalAccounts',
              data: [28, 40, 36, 52, 38, 60, 55]
            }
          ]
        },
        totalEarnings: {
          result: this.resumeData.statistics.total_earnings,
          series: [
            {
              name: 'totalEarnings',
              data: [28, 40, 36, 52, 38, 60, 55]
            }
          ]
        }
      }
    }
  },
  mounted () {
    this.getResumeData()
  },
  methods: {
    kFormatter,
    async getResumeData () {
      this.loading = true
      const url = '/user/controle_infinity/resume/all'

      this.$http.get(url).then(res => {
        this.resumeData = res.data
      })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
